<template>
    <div :class="['church-icon', className]">
        <svg
            v-if="icon == 'file'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'card'"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 21"
        >
            <g id="Recommend" transform="translate(-60 -544)">
                <rect
                    id="Rectangle_78"
                    data-name="Rectangle 78"
                    width="36"
                    height="21"
                    transform="translate(60 544)"
                    fill="currentColor"
                />
                <g
                    id="Group_75"
                    data-name="Group 75"
                    transform="translate(0.204 -1)"
                >
                    <path
                        id="Temple"
                        d="M.026,11.127c0-.05,0-.1,0-.149q0-3.164,0-6.328a.652.652,0,0,0-.014-.171c-.019-.071-.011-.132.107-.12.06.006.075-.026.076-.071,0-.133.019-.267.017-.4,0-.082.033-.113.125-.1C.448,3.8.5,3.77.486,3.664A2.971,2.971,0,0,1,.759,2.311c.073-.223.148-.445.227-.683a.237.237,0,0,1,.078.152,3.278,3.278,0,0,0,.253.752A2.315,2.315,0,0,1,1.51,3.655c-.012.1.028.151.154.133s.148.026.142.121c-.009.122,0,.245,0,.367,0,.054.013.093.088.085C2,4.348,2,4.406,2,4.466,2,4.81,2,5.154,2,5.5c0,.064.012.129.018.193-.049.087.014.092.089.087.131,0,.261,0,.391,0,.112.006.148-.029.147-.124,0-.573,0-1.147,0-1.72,0-.092-.035-.209.166-.153.068.019.105-.023.1-.086,0-.122,0-.245,0-.367,0-.077.03-.1.117-.1.208,0,.219,0,.206-.183A4.258,4.258,0,0,1,3.4,1.774c.088-.376.187-.75.269-1.128A3.568,3.568,0,0,1,3.844,0c.052.206.1.388.143.57.15.631.3,1.261.445,1.893a3.122,3.122,0,0,1,.014.618c0,.153,0,.156.179.148.077,0,.093.026.093.08a1.931,1.931,0,0,1,0,.309c-.021.127.063.171.175.162.129-.01.127.043.127.119,0,.577,0,1.154,0,1.731,0,.122.056.152.179.15.143,0,.287,0,.431,0,.069,0,.116-.017.127-.089a.957.957,0,0,0,.018-.147c0-.359,0-.718,0-1.078,0-.064,0-.114.1-.108.076,0,.084-.043.084-.093,0-.119.006-.237,0-.355-.006-.1.036-.134.148-.124.07.006.14.006.129-.084a3.1,3.1,0,0,1,.278-1.4c.073-.214.142-.429.217-.653a.149.149,0,0,1,.083.127,4.608,4.608,0,0,0,.307.89,1.979,1.979,0,0,1,.143.843,1.179,1.179,0,0,0,.005.172c0,.077.04.122.142.106.123-.02.156.029.148.128-.009.118,0,.237,0,.355,0,.053.017.094.089.089s.1.028.11.084A.613.613,0,0,1,7.76,4.6q0,3.188,0,6.375c0,.05,0,.1,0,.149Z"
                        transform="translate(64.795 550)"
                        fill="#fff"
                    />
                    <g
                        id="Group_74"
                        data-name="Group 74"
                        transform="translate(0 2.5)"
                    >
                        <line
                            id="Line_1"
                            data-name="Line 1"
                            x2="12"
                            transform="translate(76.5 550.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                        />
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            x2="8"
                            transform="translate(76.5 556.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                        />
                        <line
                            id="Line_2"
                            data-name="Line 2"
                            x2="15"
                            transform="translate(76.5 553.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="icon == 'bell'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'arrow-out'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    fill-rule="evenodd"
                    d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
                ></path>
                <path
                    fill-rule="evenodd"
                    d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'search'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'arrow-left'"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'arrow-right'"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
            />
        </svg>

        <svg
            v-if="icon == 'expand'"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
                d="m13.28 7.78 3.22-3.22v2.69a.75.75 0 0 0 1.5 0v-4.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0 0 1.5h2.69l-3.22 3.22a.75.75 0 0 0 1.06 1.06ZM2 17.25v-4.5a.75.75 0 0 1 1.5 0v2.69l3.22-3.22a.75.75 0 0 1 1.06 1.06L4.56 16.5h2.69a.75.75 0 0 1 0 1.5h-4.5a.747.747 0 0 1-.75-.75ZM12.22 13.28l3.22 3.22h-2.69a.75.75 0 0 0 0 1.5h4.5a.747.747 0 0 0 .75-.75v-4.5a.75.75 0 0 0-1.5 0v2.69l-3.22-3.22a.75.75 0 1 0-1.06 1.06ZM3.5 4.56l3.22 3.22a.75.75 0 0 0 1.06-1.06L4.56 3.5h2.69a.75.75 0 0 0 0-1.5h-4.5a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 0 1.5 0V4.56Z"
            />
        </svg>

        <svg
            v-if="icon == 'compress'"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
                d="M3.28 2.22a.75.75 0 0 0-1.06 1.06L5.44 6.5H2.75a.75.75 0 0 0 0 1.5h4.5A.75.75 0 0 0 8 7.25v-4.5a.75.75 0 0 0-1.5 0v2.69L3.28 2.22ZM13.5 2.75a.75.75 0 0 0-1.5 0v4.5c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-2.69l3.22-3.22a.75.75 0 0 0-1.06-1.06L13.5 5.44V2.75ZM3.28 17.78l3.22-3.22v2.69a.75.75 0 0 0 1.5 0v-4.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0 0 1.5h2.69l-3.22 3.22a.75.75 0 1 0 1.06 1.06ZM13.5 14.56l3.22 3.22a.75.75 0 1 0 1.06-1.06l-3.22-3.22h2.69a.75.75 0 0 0 0-1.5h-4.5a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 0 1.5 0v-2.69Z"
            />
        </svg>

        <svg
            v-if="icon == 'clock'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
        </svg>

        <svg
            v-if="icon == 'pin'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: () => null,
        },
        className: {
            type: String,
            default: () => "",
        },
    },
};
</script>

<style lang="scss">
@import "@/scss/partials/_colors.scss";

.church-icon {
    width: 25px;
    height: 25px;
    color: $gray6;

    svg {
        width: inherit !important;
        height: inherit !important;
        color: inherit;
    }
}
</style>
